<template>
  <section class="section exportPDF">
    <div class="pdfTitle">{{info.xm}}的个人资料</div>
    <el-form ref="form" :model="form.data" label-width="120px" :rules="form.rules" class="form">
      <el-col :span="8">
        <el-form-item label="姓名：" prop="xm">
          <el-input v-model="form.data.xm" placeholder="请输入姓名" v-if="form.data.xm"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="性别：" prop="xb">
          <el-select v-model="form.data.xb" placeholder="请选择性别" v-if="form.data.xb">
            <el-option v-for="item in form.options.xb.children" :key="item.id" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="重大事项：">
          <el-select v-model="form.data.grzdsxbh" placeholder="请选择重大事项" v-if="form.data.grzdsxbh">
            <el-option
              v-for="item in form.options.grzdsxbh.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="民族：" prop="mz">
          <el-select v-model="form.data.mz" placeholder="请选择民族" v-if="form.data.mz">
            <el-option v-for="item in form.options.mz.children" :key="item.id" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="政治面貌：" prop="zzmm">
          <el-select v-model="form.data.zzmm" placeholder="请选择政治面貌" v-if="form.data.zzmm">
            <el-option
              v-for="item in form.options.zzmm.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="报名方式：" prop="bmfs">
          <el-select v-model="form.data.bmfs" placeholder="请选择报名方式" v-if="form.data.bmfs">
            <el-option
              v-for="item in form.options.bmfs.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业院校：">
          <el-input v-model="form.data.byyx" placeholder="请输入毕业院校" v-if="form.data.byyx"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="文化程度：">
          <el-popover placement="bottom" popper-class="popover">
            <el-tree
              ref="whcd"
              :data="form.options.whcd.children"
              accordion
              node-key="id"
              highlight-current
              :current-node-key="form.data.whcd"
              @node-click="whcdClick"
              placeholder="请选择文化程度"
            ></el-tree>
            <el-select slot="reference" v-model="form.data.whcd" @focus="setwidth($event,'whcd')" placeholder="请选择文化程度"></el-select>
          </el-popover>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业专业：">
          <el-input v-model="form.data.byzy" placeholder="请输入毕业专业" v-if="form.data.byzy"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="身份证号码：" prop="sfzhm">
          <el-input v-model.number="form.data.sfzhm" placeholder="请输入身份证号码" v-if="form.data.sfzhm"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系电话：" prop="lxdh">
          <el-input v-model="form.data.lxdh" placeholder="请输入联系电话" v-if="form.data.lxdh"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="QQ号码：">
          <el-input v-model="form.data.qqhm" placeholder="请输入QQ号码" v-if="form.data.qqhm"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="微信号：">
          <el-input v-model="form.data.wxh" placeholder="请输入微信号" v-if="form.data.wxh"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="电子邮箱：">
          <el-input v-model="form.data.dzyx" placeholder="请输入电子邮箱" v-if="form.data.dzyx"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="邮政编码：">
          <el-input v-model="form.data.yzbm" placeholder="请输入邮政编码" v-if="form.data.yzbm"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="户籍地：">
          <v-distpicker :province="form.data.hjd && form.data.hjd.province"></v-distpicker>
        </el-form-item>
      </el-col>

      <el-col :span="11">
        <el-form-item label="户籍地详细地址：" prop="hjdxxdz" label-width="160px">
          <el-input v-model="form.data.hjdxxdz" placeholder="请输入户籍地详细地址" v-if="form.data.hjdxxdz"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="居住地：" class="location">
          <v-distpicker :province="form.data.jzd && form.data.jzd.province"></v-distpicker>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="居住地详细地址：" prop="jzdxxdz" label-width="160px">
          <el-input v-model="form.data.jzdxxdz" placeholder="请输入居住地详细地址" v-if="form.data.jzdxxdz"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="通信地址">
          <v-distpicker :province="form.data.txdz && form.data.txdz.province"></v-distpicker>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="通信地址详细地址：" prop="txdzxxdz" label-width="160px">
          <el-input v-model="form.data.txdzxxdz" placeholder="请输入通信地址详细地址" v-if="form.data.txdzxxdz"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <el-form ref="form" :model="form.data" label-width="120px" :rules="form.rules">
      <el-col :span="8">
        <el-form-item label="工作单位：" prop="gzdw">
          <el-input v-model="form.data.gzdw" placeholder="请输入工作单位" v-if="form.data.gzdw"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="单位性质：" prop="dwxz">
          <el-select v-model="form.data.dwxz" placeholder="请选择单位性质" v-if="form.data.dwxz">
            <el-option
              v-for="item in form.options.dwxz.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="职务级别：">
          <el-select v-model="form.data.zwjb" placeholder="请选择职务级别" v-if="form.data.zwjb">
            <el-option
              v-for="item in form.options.zwjb.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="参加工作时间：">
          <el-date-picker
            v-model="form.data.cjgzsj"
            value-format="yyyy-MM-dd"
            type="datetime"
            placeholder="请选择参加工作时间"
            v-if="form.data.cjgzsj"
          ></el-date-picker>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="执业类别：">
          <el-select v-model="form.data.zylb" placeholder="请选择执业类别" v-if="form.data.zylb">
            <el-option
              v-for="item in form.options.zylb.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="人大代表：">
          <el-select v-model="form.data.rddb" placeholder="请选择是否人大代表" v-if="form.data.rddb">
            <el-option
              v-for="item in form.options.rddb.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="政协委员：">
          <el-select v-model="form.data.zxwy" placeholder="请选择是否政协委员" v-if="form.data.zxwy">
            <el-option
              v-for="item in form.options.zxwy.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否两院院士：">
          <el-select v-model="form.data.sflyys" placeholder="请选择是否两院院士" v-if="form.data.sflyys">
            <el-option
              v-for="item in form.options.sflyys.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否学部委员：">
          <el-select v-model="form.data.sfxbwy" placeholder="请选择是否学部委员" v-if="form.data.sfxbwy">
            <el-option
              v-for="item in form.options.sfxbwy.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否村委会工作人员：" label-width="160px">
          <el-select v-model="form.data.sfcwhgzry" placeholder="请选择是否村委会工作人员" v-if="form.data.sfcwhgzry">
            <el-option
              v-for="item in form.options.sfcwhgzry.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="社会兼职：">
          <el-input v-model="form.data.shjz" placeholder="请输入社会兼职" v-if="form.data.shjz"></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <el-form ref="form" :model="form.data" label-width="180px" :rules="form.rules">
      <el-col :span="12">
        <el-form-item label="是否法律工作者：" prop="sfflgzz">
          <el-select v-model="form.data.sfflgzz" placeholder="请选择是否法律工作者" v-if="form.data.sfflgzz">
            <el-option
              v-for="item in form.options.sfflgzz.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否公务员/事业编：" prop="sfzbry">
          <el-select v-model="form.data.sfzbry" placeholder="请选择是否公务员/事业编" v-if="form.data.sfzbry">
            <el-option
              v-for="item in form.options.sfzbry.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="家庭及主要社会关系：">
          <el-input
            v-model="form.data.jtjzyshgx"
            type="textarea"
            :autosize="{minRows:3,maxRows:10}"
            placeholder="请输入家庭及主要社会关系："
            v-if="form.data.jtjzyshgx"
          ></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="何时何地受过何种奖励：">
          <el-input
            v-model="form.data.sddjl"
            type="textarea"
            :autosize="{minRows:3,maxRows:10}"
            placeholder="请输入何时何地受过何种奖励："
            v-if="form.data.sddjl"
          ></el-input>
          <el-input placeholder="暂无数据" v-else></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="file-wrap">
      <div class="item mr" v-show="!form.data.grzp">
        <img src="./img/img1.png" alt="照片" />
      </div>
      <div class="item mr" v-if="form.data.grzp && form.data.grzp.path">
        <img class="grzp_img" :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.grzp.path" alt="照片" />
      </div>

      <div class="item mr" v-show="!form.data.sfztxm">
        <img src="./img/img2.png" alt="身份证头像面" />
      </div>
      <div class="item mr" v-if="form.data.sfztxm && form.data.sfztxm.path">
        <img class="sfztxm_img" :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfztxm.path" alt="照片" />
      </div>

      <div class="item" v-show="!form.data.sfzghm">
        <img src="./img/img3.png" alt="身份证国徽面" />
      </div>
      <div class="item" v-if="form.data.sfzghm && form.data.sfzghm.path">
        <img class="sfzghm_img" :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfzghm.path" alt="照片" />
      </div>
    </div>
  </section>
</template>

<script>
import Candidate from '@/api/choose/candidate';
import VDistpicker from 'v-distpicker';
export default {
  name: '',
  props: { dic: Object },
  components: { VDistpicker },
  data() {
    return {
      form: {
        data: {},
        options: {
          xb: { children: [] },
          grzdsxbh: { children: [] },
          mz: { children: [] },
          zzmm: { children: [] },
          whcd: { children: [] },
          bmfs: { children: [] },
          dwxz: {
            children: [],
          },
          zylb: {
            children: [],
          },
          zwjb: {
            children: [],
          },
          zxwy: {
            children: [],
          },
          sfxbwy: {
            children: [],
          },
          sfcwhgzry: {
            children: [],
          },
          sflyys: {
            children: [],
          },
          rddb: {
            children: [],
          },
          sfflgzz: {
            childrens: [],
          },
          sfzbry: {
            childrens: [],
          },
        },
      },
      info: {},
      whcdwidth: '',
    };
  },
  methods: {
    // 设置树组件的宽度与当前元素宽度一致，解决树组件过小的问题
    setwidth(e, type) {
      this[type + 'width'] = e.target.offsetWidth;
    },
    whcdClick(data) {
      if (data.has_child) {
        return;
      } else {
        this.form.data.whcd = data.label;
      }
    },
    toggle(data) {
      Candidate.detail_sfs(data.sfzhm).then((res) => {
        let info = JSON.parse(JSON.stringify(res.person));
        // 修改需要移除_id
        delete info._id;
        this.info = info;
        let { grzp, sfztxm, sfzghm } = info;
        this.form.data = info;
        console.log(info);
        let list = [grzp, sfztxm, sfzghm];
        let length = list.reduce((pre, item) => {
          if (item) {
            return pre + 1;
          } else {
            return pre;
          }
        }, 0);
        window.postMessage({
          type: 'PDF',
          data: {
            type: 'length',
            data: length,
          },
        });
        this.$nextTick(() => {
          if (grzp) {
            let grzp_img = document.querySelector('.grzp_img');
            grzp_img.onload = function () {
              window.postMessage({
                type: 'PDF',
                data: {
                  type: 'ok',
                  data: 'grzp',
                },
              });
            };
          }
          if (sfztxm) {
            let sfztxm_img = document.querySelector('.sfztxm_img');
            sfztxm_img.onload = function () {
              window.postMessage({
                type: 'PDF',
                data: {
                  type: 'ok',
                  data: 'sfztxm',
                },
              });
            };
          }
          if (sfzghm) {
            let sfzghm_img = document.querySelector('.sfzghm_img');
            sfzghm_img.onload = function () {
              window.postMessage({
                type: 'PDF',
                data: {
                  type: 'ok',
                  data: 'sfzghm',
                },
              });
            };
          }
        });
      });
    },
  },
  mounted() {},
  created() {
    this.form.options = this.$store.getters.dic;
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.section {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .pdfTitle {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #282828;
    padding-bottom: 20px;
  }
}
::v-deep .distpicker-address-wrapper {
  display: flex;
  label {
    flex: 1;
    select {
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }
  label:nth-of-type(-n + 2) {
    margin-right: 10px;
  }
}
.el-select {
  width: 100%;
}
::v-deep .el-date-editor {
  width: 100%;
}
.el-select {
  width: 100%;
}
.file-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  box-sizing: border-box;
  .item {
    flex: 1;
    height: 270px;
    border: 1px dashed #d9d9d9;
    display: flex;
    border-radius: 8px;
    position: relative;
    img {
      margin: auto;
      max-height: 270px;
      max-width: 428px;
    }
    .text {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 10px;
      .blue {
        color: #409eff;
      }
    }
  }
  .item:hover {
    border-color: #409eff;
    cursor: pointer;
  }
  .mr {
    margin-right: 20px;
  }
}
.a {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
